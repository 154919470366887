.alink {
    color: white !important;
    text-decoration: none !important;
    font-size: 20px !important;
}

.alink:hover {
    color: red !important;
    text-decoration: underline !important;
    font-size: 25px !important;
    transition: 0.3s ease-in-out !important;
}


.footer-container {
    background-color: #272B36;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
}

.footer-subscription>p {
    font-family: 'Gill Sans', Calibri;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

/* .footer-horario {
    color: #fff;
    align-items: center;
}

.footer-horario .subtitle {
    text-align: justify;
} */

.work-hour {
    align-items: center;
}

.footer-container .line[data-v-4b63570f] {
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, .3);

}

.mt42 {
    margin-top: 2.1875vw !important;
}

.footer-container .menu[data-v-4b63570f] {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #d20000;

}

.menu {
    margin-top: 40px;
    color: #d20000;

}

.item {
    font-size: large;
    color: #d20000;
}

.footer-link-items>h2 {
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

.dunlop-logo {
    margin-top: 20px;
    align-items: center;
}

.social-icons {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-icons:hover {
    color: rgb(255, 0, 0);
    font-size: 40px;
    transition: ease-in-out 0.2s;
}

.social-media {
    max-width: 1000px;
    width: 100%;
    align-items: center;
}

.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-logo {
    color: #fff;
    cursor: pointer;
    align-items: center;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
}

.chengshan-ven {
    color: #d20000 !important;
}

@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-container .menu {
        flex-direction: column;
    }

    .menu {
        flex-direction: column;
    }

    .item {
        flex-direction: column;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .social-media-wrap {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {}