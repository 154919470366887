.svg-body {
    background-color: #212529;

}

.cards1 {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    color: #ffffff;
    background-color: #212529;
    font-family: 'Gill Sans', Calibri;
}

.video-mapa {
    /* padding-top: 80px; */
    padding-bottom: 40px;
    max-height: 100%;
    max-width: auto;
    font-family: 'Gill Sans', Calibri;
}

.comercios {
    font-size: 30px;
    margin-top: 20px;
    font-family: 'Gill Sans', Calibri;
}

.svg-container {
    width: 90%;
    height: 90%;
}

.svg {
    width: 100%;
    height: 100%;
}

#mapa path {
    fill: #D20000;
    stroke: #ffffff;
    stroke-width: 2px;
}

#mapa :hover {
    fill: #000;
}