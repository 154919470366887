.container-body {
    background-image: url("/public/images/fondo-informacion.webp");
    width: 100%;
    height: auto;
}

.slogan {
    color: #fff;
    font-size: 26px;
    font-weight: bold;
}

.description {
    color: #fff;
    font-size: 16px;
}

.caracteristics {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

.history {
    color: #fff;
    font-size: 16p16
}