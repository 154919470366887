p {
    margin-top: 20px;
    line-height: 1.6;
}

a {
    color: #ffffff !important;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

.logo {
    display: block;
    margin: 30px auto 0 auto;
    width: 100px;
    height: 100px;
}

/* .heading {
    margin-bottom: 0.5em;
}
.heading--2 {
    font-size: 30px;
} */
.lanes {
    display: inline-block;
}

.lane {
    padding: 50px 0;
    text-align: left;
}

.lane+.lane {
    border-top: 1px solid white;
}

.lane__title {
    display: inline-block;
    color: white;
    margin-right: 10px;
    font-size: 20px;
    width: 80px;
    text-align: right;
}

.lane__item {
    display: inline-block;
    margin-right: -4px;
}

.lane__item+.lane__item:before {
    content: '|';
    color: white;
    font-size: 1em;
    vertical-align: middle;
    margin: 0 0.5em;
}

.direct {
    display: inline-block;
    text-decoration: none;
    background-color: #272B36;
    font-size: 15px;
    border: 3px solid #272B36;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.1s ease-out;
}

.direct:hover {
    background-color: #D20000;
    border-color: #D20000;
    text-decoration: none;
}

.descriptions {
    position: relative;
    background-color: #272B36;
    color: white;
    padding: 20px;
    border-radius: 10px;
    min-height: 100px;
    max-width: 730px;
    margin: 40px auto;
}

.description {
    display: none;
}

.description:target,
.description--placeholder {
    display: block;
}

.description:target~.description--placeholder {
    display: none;
}

.description:target~.description__close {
    display: block;
}

.description:not(.description--placeholder) {
    text-align: left;
}

.description__close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 25px;
    transition: opacity 0.2s ease-out;
    display: none;
}

.contact {
    /* background-color: #222; */
    background-image: url('../../../public/images/fondo-contacto.webp');
    background-size: cover;
    font-family: 'Gill Sans', Calibri;
    text-align: center;
}