.comerce {
  background-color: #212529 !important;
  font-family: 'Gill Sans', Calibri;
}

h2 {
  font-weight: bold !important;
  color: #fff;
}

.accordion {
  --bs-accordion-active-bg: #D20000 !important;
  --bs-accordion-btn-focus-border-color: black !important;
  --bs-accordion-active-color: #fff !important;
  --bs-accordion-btn-focus-border-color: #D20000 !important;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
  --bs-accordion-btn-focus-box-shadow: black !important;
}

.acordion-header {
  align-items: center !important;
  font-weight: bold !important;
}

.accordion-button {
  font-weight: 600;
}

.ubic {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.ubic iframe,
.ubic object,
.ubic embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.contacts {
  color: #000000;
}

.contacts:hover {
  color: #D20000;
}

.contacts:active {
  color: #D20000;
}