/* .all {
    margin: 0;
    padding: 0;
}

#fondo-productos {
    position: relative;
    z-index: 1;
}

.fondo {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
} */

.from-control {
    width: 90% !important;
    border-radius: 15px !important;
    height: 60px !important;
    font-size: 17px !important;
    text-align: center !important;
    color: black !important;
    transition: width 450ms !important;
}

.from-control:hover {
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.521) !important;
    transition: all 0.2s ease-in-out !important;
    width: 100% !important;
    font-size: 18px !important;
    transition: width 550ms !important;
}

.from-control:focus {
    width: 100% !important;
    opacity: 1 !important;
}
.buscador{
    text-align: center !important;
    /* justify-items: center !important;
    justify-content: center !important; */
}

.card {
    background-color: #272B36;
}

.card-img-top{
    padding-top: 20px;
}

.card-title {
    color: #fff;
}

.card-text {
    color: #fff;
}

.fa-magnifying-glass {
    color: #ffffff !important;
    text-shadow: 2px 2px 0px #333333, 5px 4px 0px rgba(0,0,0,0.15) !important;
}

.form-lable {
    color: #FFFFFF;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.btn-product {
    font-size: 18px;
    background-color: #D20000 !important;
    width: 100%;
    margin-top: 10px;
    transition: transform 450ms !important;

}

.btn-product:hover {
    transform: translateY(-4px);
    background-color: #ac0a0a !important;
}

/* .nothing {
    color: #fff;
} */