video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero-container {
    height: 80% !important;
    /* width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    object-fit: contain; */
    background-color: #212529;
    font-family: 'Gill Sans', Calibri;
}

.hero-container>h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.hero-container>p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Gill Sans', Calibri;
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 70px;
        margin-top: -150px;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container>p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}